import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import Image from '~components/Image'
import '~styles/flickity.css'
import canUseDOM from '~utils/canUseDOM'
import Section from '~components/Section'
import { ChevronRight } from '~components/Svg'
import useBreakpoint from '~utils/useBreakpoint'

const HeaderSlider = ({ className, slides }) => {

	const isMobile = useBreakpoint()

	const [init, setInit] = useState()

	const flkty = useRef()
	const flickityEl = useRef()

	useEffect(() => {
		if (canUseDOM && init){
			const Flickity = require('flickity')
			flkty.current = new Flickity(flickityEl.current, flickityOptions)
		}
		setInit(true)
	}, [init])

	const nextSlide = () => {
		flkty?.current?.next()
	}

	const prevSlide = () => {
		flkty?.current?.previous()
	}

	const flickityOptions = {
		prevNextButtons: false,
		pageDots: false,
		adaptiveHeight: false,
		wrapAround: true,
		cellAlign: 'left',
		freeScroll: false,
		contain: true,
		draggable: true,
	}

	return (
		<Wrap className={className}>
			<SliderContainer>
				<Slider ref={flickityEl}>
					{slides.map(slide => (
						<Slide key={slide.asset?._id}>
							<Image image={slide} aspectRatio={isMobile ? 1.49 : 1.78}/>
						</Slide>
					))}		
				</Slider>
				<ArrowBar>
					<Left onClick={() => prevSlide()}>
						<LeftArrow />
					</Left>	
					<Right onClick={() => nextSlide()}>
						<RightArrow />
					</Right>
				</ArrowBar>	
			</SliderContainer>
		</Wrap>
	)
}

const Wrap = styled(Section)`
	margin-top: 120px;
	${mobile}{
		margin-top: 0;
	}
`
const Slider = styled.div`
`
const SliderContainer = styled.div`
	grid-column: span 12;
	max-width: 1070px;
	width: 100%;
	margin: 0 auto;
	border-radius: 15px;
	overflow: hidden;
	background-color: var(--grey);
	${mobile}{
		border-radius: 10px;
	}
`
const Slide = styled.div`
	width: 100%;
	height: 0;
	padding-bottom: 56%;
	${mobile}{
		padding-bottom: 66%;
	}
`
const ArrowBar = styled.div`
	height: 50px;
	display: flex;
	justify-content: space-between;
	${mobile}{
		height: 36px;
	}
`
const Right = styled.button`
	width: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-left: 1px solid var(--extraDarkGrey);
	${mobile}{
		width: 38px;
	}
`
const Left = styled(Right)`
	border-right: 1px solid var(--extraDarkGrey);
	border-left: none;
`
const RightArrow = styled(ChevronRight)`
	width: 10px;
	color: var(--extraDarkGrey);
`
const LeftArrow = styled(RightArrow)`
	transform: rotate(180deg);
`


HeaderSlider.propTypes = {
	className: PropTypes.string,
	slides: PropTypes.array,
}

export default HeaderSlider