import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import RichText from '~components/RichText'
import Seo from '~components/Seo'
import Section from '~components/Section'
import DataViewer from '~utils/DataViewer'
import HeaderSlider from '~components/HeaderSlider'
import HeadingTextModule from '~components/HeadingTextModule'
import Stats from '~components/Stats'
import { mobile, ChevronLeft, BackLink, ChevRight } from '~styles/global'
import Image from '~components/Image'
import ContactFormModule from '~components/ContactFormModule'
import SanityLink from '~components/SanityLink'

const Post = ({ data }) => {
	const page = data?.article || data?.story

	const posts = data?.article ? data?.allArticles?.nodes : data?.allStories?.nodes

	function lexographicalSort(a, b) {
		if (a['datePublished'] < b['datePublished']) {
			return -1
		}
		if (a['datePublished'] > b['datePublished']) {
			return 1
		}
		return 0
	}
	posts.sort(lexographicalSort)

	const currentProjectIndex = posts.findIndex(post => {
		return post.title === page.title
	})

	const pageContent = page.content ? page.content : []

	console.log(posts[currentProjectIndex + 1])

	return(
		<>
			<Seo 
				title={page.title}
				metaTitle={page.seo?.metaTitle}
				description={page.seo?.metaDescription}
				image={page.seo?.socialImage}
			/>
			<MobileSection>
				<BackContainer>
					<BackLink to={page._type === 'article' ? `/${data?.journalPage?.slug?.current}` : `/${data?.storiesPage?.slug?.current}`}><ChevronLeft /> Back</BackLink>
				</BackContainer>
			</MobileSection>
			<HeaderSlider slides={page.gallery}/>
			{page._type === 'article' ?
				null
				:
				<Introduction
					content={{heading: page.title, text: page.introduction}}
				/> 
			}
			{page.stats && <Stats stats={page.stats}/>}
			<ContentSection>
				<Container>
					<RichText 
						content={page._type === 'article' ? 
							[
								{ 
									_type: 'pageHeading',
									_key: 'pageHeading',
									title: page?.title,
									category: page?.category,
									productType: page?.productType,
									backLink: `/${data?.journalPage?.slug?.current}`
								}, 
								...pageContent
							]: 
							pageContent
						} 
						styledText
					/>
				</Container>
			</ContentSection>
			{page._type === 'story' &&
				<ContactFormModule grey/>
			}
			{page._type === 'article' &&
				<Section>
					<PrevNext>
						<PrevNextInner>
							<Prev link={{
								linkType: 'internal',
								document: posts[currentProjectIndex === 0 ? posts.length - 1 : currentProjectIndex - 1]
							}}>
								<ChevLeft />Previous
							</Prev>
							<Next link={{
								linkType: 'internal',
								document: posts[currentProjectIndex === posts.length - 1 ? 0 : currentProjectIndex + 1]
							}}>
								Next<ChevronRight/>
							</Next>
						</PrevNextInner>
					</PrevNext>
				</Section>
			}
			<DataViewer data={page} name="content"/>
		</>
	)
}

const Introduction = styled(HeadingTextModule)`
`
const Container = styled.div`
	grid-column: span 12;
	max-width: 850px;
	margin: 0 auto;
	width: 100%;
`
const MobileSection = styled(Section)`
	display: none;
	${mobile}{
		display: block;
	}
`
const BackContainer = styled.div`
	grid-column: span 12;
	margin-top: 30px;
	margin-bottom: 8px;
`
const ContentSection = styled(Section)`
	margin-top: 60px;
	margin-bottom: 60px;
	${mobile}{
		margin-top: 30px;
	}
`
const PrevNext = styled.div`
	grid-column: span 12;
	display: grid;
	grid-template-columns: repeat(8, 1fr);
	grid-column-gap: 30px;
	max-width: 850px;
	margin: 0 auto;
	margin-bottom: 70px;
	width: 100%;
	${mobile}{
		grid-column-gap: 0px;
		margin-bottom: 60px;
	}
`
const PrevNextInner = styled.div`
	grid-column: 4/9;
	display: flex;
  justify-content: space-between;
	${mobile}{
		grid-column: span 12;
	}
`
const Prev = styled(SanityLink)`
	display: flex;
	align-items: center;
`
const ChevLeft = styled(ChevronLeft)`
	margin-right: 12px;
`
const Next = styled(SanityLink)`
	display: flex;
	align-items: center;
	
`
const ChevronRight = styled(ChevRight)`
	margin-left: 12px;
`
Post.propTypes = {
	data: PropTypes.object,
}

export const query = graphql`
  query PostQuery($slug: String, $isArticle: Boolean = false, $isStory: Boolean = false) {
    article: sanityArticle(slug: {current: {eq: $slug}}) @include(if: $isArticle) {
      title
			_type
			datePublished
			category {
				_type
				title
				slug {
					current
				}
			}
			productType {
				_type
				title
				slug {
					current
				}
			}
			gallery {
				...imageWithCaption
			}
			content: _rawContent(resolveReferences: { maxDepth: 10 })
			seo{
				...seo
			}
    }
		allArticles : allSanityArticle {
			nodes {
				_type
				title
				datePublished
				slug{
					current
				}
			}
		}
		story: sanityStory(slug: {current: {eq: $slug}}) @include(if: $isStory) {
      title
			_type
			gallery {
				...imageWithCaption
			}
			introduction: _rawIntroduction
			stats {
				stat
				text
			}
			content: _rawContent(resolveReferences: { maxDepth: 10 })
			seo{
				...seo
			}
    }
		allStories: allSanityStory{
			nodes {
				_type
				title
				datePublished
				slug{
					current
				}
			}
		}
		storiesPage: sanityStoriesIndex{
			slug{
				current
			}
		}
		journalPage: sanityJournalIndex {
			slug {
				current
			}
		}
  }
`

export default Post