import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import RichText from '~components/RichText'
import Section from '~components/Section'

const HeadingTextModule = ({ className, content }) => {
	return (
		<Wrap className={className}>
			<Container>
				<Heading>{content?.heading}</Heading>
				<Text>
					<RichText content={content?.text}/>
				</Text>
			</Container>
		</Wrap>
	)
}

const Wrap = styled(Section)`
	margin: 60px 0 60px;
	${mobile}{
		margin: 28px 0 28px;
	}
`
const Container = styled.div`
	grid-column: span 12;
	max-width: 850px;
	display: grid;
	grid-template-columns: repeat(8, 1fr);
	grid-column-gap: 30px;
	margin: 0 auto;
	width: 100%;
`
const Heading = styled.h2`
	grid-column: 1/4;
	${mobile}{
		grid-column: span 8;
		margin-bottom: 56px;
	}
`
const Text = styled.div`
	grid-column: 4/9;
	${mobile}{
		grid-column: span 8;
	}
`

HeadingTextModule.propTypes = {
	className: PropTypes.string,
	content: PropTypes.object,
	category: PropTypes.object,
	type: PropTypes.object,
	back: PropTypes.bool,
	backLink: PropTypes.string,
}

export default HeadingTextModule